import * as React from 'react';

export default function FilterIndicator() {
  return (
    <div id="loading">
        <div id="loader">
            <img src="/assets/images/loader.svg" alt="" />
        </div>
    </div>
  )
}