import BaseService from "./baseService";

class CompareService extends BaseService {
  protected namespace: string = 'compare';

  public newInstance() {
    return new CompareService;
  }
}

export default new CompareService;