import * as React from 'react';
import { IAdvertisement } from '@temsa/interfaces/generic';
import { lookup } from '@temsa/helpers/utils';
import Advertisement from '@temsa/react/models/Advertisement';

const options = [
  'price',
  'model',
  'year',
  'mileage',
  'euro_norm',
  'registration_date',
  'number_of_seats',
  'seats_layout',
  // 'seats',
  'engine_power',
  'fuel_type',
  'gearing_type',
  'gvwr',
  'color',
  'condition',
];

export default function CompareRow({item, onRemove, trans}: {item: IAdvertisement, trans: (key: string) => string, onRemove: (id: string) => any}) {
  const model = `${item.year} ${lookup(item.brand)} ${lookup(item.model)}`;
  const adModel: any = new Advertisement(item);
  const contactUrl = `${adModel.detailUrl}/contact`;

  return (
    <div className="compare-row">
      <header className="compare-row-header" >
        <div className="card compare-card border-0">
          <button onClick={() => onRemove(item._id)} type="button" className="remove-item" >
            <svg className="icon icon-times" dangerouslySetInnerHTML={{__html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-times"></use>'}} />
          </button>
          <img className="card-img-top" src={item.media[0]} alt={model} />
          <h5 className="card-title">{model}</h5>
          <div className="scroll-block-div">{model}</div>
        </div>
      </header>
      <ul className="info-list" >
      {options.map(key => (
        <li key={key}>{adModel[key] || '-'}</li>
      ))}
      </ul>
      <footer className="compare-row-footer" >
        <a href={adModel.detailUrl} className="btn btn-link">{trans('more-detail')}
          <svg className="icon icon-long-arrow-right" dangerouslySetInnerHTML={{__html: '<use xlink:href="/assets/images/symbol-defs.svg#icon-long-arrow-right"></use>'}} />
        </a>
        <a href={contactUrl} className="btn btn-dark btn-block" >{trans('compare-call-to-action')}</a>
      </footer>
    </div>
  );
}