import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as isEqual from 'lodash.isequal';
import { parse } from 'query-string';
import { runIf, location as fullRegion, get, has, positiveInt } from '@temsa/helpers/utils';
import { Provider, connect } from 'react-redux';
import container from '@temsa/reducers';
import CompareRow from './stateless/CompareRow';
import CompareModel from '../../models/Compare';
import FilterIndicator from '../filter/stateless/FilterIndicator';
import compareService from '@temsa/react/services/compareService';
import BaseService from '@temsa/react/services/baseService';
import { showToast } from '@temsa/react/actions/toast';
import CardActionHoc from '../../hocs/CardActionHoc';
import { compose } from 'redux';
import translations from '@temsa/translations';
import { share } from '../header/ShareWidget';
import Advertisement from '@temsa/react/models/Advertisement';
import { removeComparisionAction } from '@temsa/react/actions/comparision';
import TranslationHoc, { ITranslationHocProps } from '@temsa/react/hocs/TranslationHoc';
import { IKeyValueObject, IPersistItem, IHttpResponse, IAdvertisement } from '@temsa/interfaces/generic';

type ICompareAllProps = ICompareProps & ITranslationHocProps;

interface ICompareProps {
  comparisions: IKeyValueObject[];
  compareTrans: (key: string) => string;
  removeCompareItem: (id: string) => any;
  removeComparision: (region: string, id: string) => any;
}

interface ICompareState {
  loading: boolean;
  items: IKeyValueObject[];
  transKey: string;
  ids: string;
}

class Compare extends React.Component<ICompareAllProps, ICompareState> {
  private compareService: BaseService = compareService.newInstance();

  constructor(props: ICompareAllProps) {
    super(props);
    this.state = {
      items: [],
      loading: true,
      ids: '',
      transKey: Advertisement.DETAIL_TRANSLATION_KEY,
    };

    this.compareService.setNamespace([fullRegion(), 'api', 'compare'].join('/'));
  }
  
  private get comparisions(): IPersistItem[] {
    return get(fullRegion(), this.props.comparisions) || [];
  }

  private get ids(): string {
    const qsParams = parse(decodeURIComponent(location.search), {arrayFormat: 'bracket'});
    return has('ids', qsParams) && Object.keys(qsParams).length > 0 ? qsParams.ids as string : this.comparisions.map(comp => comp.short_id).join(',');
  }

  private get emptyRows(): number[] {
    const result = (3 - this.ids.split(',').filter(Boolean).length);
    return result <= 0 ? [] : Array(positiveInt(result)).fill(0);
  }

  public componentDidUpdate(prevProps: ICompareProps) {
    const prev = get(fullRegion(), prevProps.comparisions) as IPersistItem[];
    const current = get(fullRegion(), this.props.comparisions) as IPersistItem[];

    if (! isEqual(prev, current)) {
      this.setState({ loading: true });

      setTimeout(() => {
        const ids = current.map(c => c.short_id).join(',');
        this.replaceUrl(ids);

        this.compareService.index({ query: {ids} }).then((res: IHttpResponse) => {
          this.setState({
            loading: false, 
            items: res.data.adverts.data, 
          });
        });
      }, 100);
    }
  }

  public componentDidMount() {
    const collectionId = get('advertisementDetailPage', translations);
    const { transKey } = this.state;

    this.props.getTranslations(collectionId, transKey);

    const ids = this.ids;

    this.compareService.index({ query: {ids} }).then((res: IHttpResponse) => {
      this.setState({
        ids,
        transKey,
        loading: false, 
        items: res.data.adverts.data, 
      });
    });
  }

  public render() {
    return (
      <>
      {this.state.loading && <FilterIndicator />}
      <div className="container compare-container">
        <div className="section-title section-title-sm">
          <h2>{this.props.compareTrans('compare-models')}</h2>
        </div>
        <div className="compare" >
          <div className="compare-wrapper">
            <div className="scroll-compare-bg d-none" />
            <div className="compare-row">
              <header className="compare-row-header" />
              <ul className="info-list">
                <li>{this.trans('price')}</li>
                <li>{this.trans('model')}</li>
                <li>{this.trans('year-of-manufacture')}</li>
                <li>{this.trans('vehicle-kmmiles')}</li>
                <li>{this.trans('euro-norm')}</li>
                <li>{this.trans('registration-date')}</li>
                <li>{this.trans('number-of-seats')}</li>
                {/* <li>{this.trans('seats')}</li> */}
                <li>{this.trans('seats-layout')}</li>
                <li>{this.trans('engine-power-hp')}</li>
                <li>{this.trans('fuel-type')}</li>
                <li>{this.trans('gearbox')}</li>
                <li>{this.trans('gross-vehicle-weight')}</li>
                <li>{this.trans('color')}</li>
                <li>{this.trans('condition')}</li>
              </ul>
            </div>
            {this.state.items.slice(0, 3).map(ad => (
              <CompareRow 
                key={ad._id} 
                onRemove={this.remove} 
                item={ad as IAdvertisement}
                trans={this.props.compareTrans}
              />
            ))}
            {this.emptyRows.map((r, i) => (<div key={i} className="compare-row compare-row-empty"></div>))}
          </div>
        </div>
      </div>
      </>
    );
  }

  private trans = (key: string) => get(key, this.props.trans);

  private remove = (id: string) => {
    const compare = this.state.items.find(item => item._id === id) as IAdvertisement;

    if (!!compare) {
      const params = parse(location.search) as IKeyValueObject;
      const items = [...this.state.items.filter(item => item._id !== id)];
      const pattern = new RegExp(`,?${compare.short_id}`);
      const decodedVal = decodeURIComponent(params.ids);
      const newQs = encodeURIComponent(decodedVal.replace(pattern, ''));
      this.replaceUrl(newQs);
      this.setState({items});
    }
    
    runIf(this.comparisions.length > 0, [
      () => this.props.removeCompareItem(id)
    ]);
  }

  private replaceUrl(ids: string) {
    history.replaceState({}, '', '/'+fullRegion()+'/compare?ids='+encodeURIComponent(ids));
  }
}

try {
  const mapStateToProps = (state: IKeyValueObject) => {
    return {
      comparisions: state.comparisions || {},
      compareTrans: (key: string) => get(`${CompareModel.TRANSLATION_KEY}.${key}`, state.translations.data)
    };
  };

  const mapDispatchToProps = (dispatch: CallableFunction) => {
    return {
      toast: (data: IKeyValueObject) => dispatch(showToast(data)),
      removeComparision: (region: string, id: string) => dispatch(removeComparisionAction(region, id)),
    }
  }

  const composedHocs = compose(CardActionHoc, TranslationHoc)(Compare);
  const Component = connect(mapStateToProps, mapDispatchToProps)(CardActionHoc(composedHocs));
  const el = document.querySelector('[component="compare"]');

  runIf(!!el, [
    () => {
      ReactDOM.render(
        <Provider store={container}>
          <Component />
        </Provider>, el
      );
    }
  ])
} catch (e) {
  console.error(e);
}