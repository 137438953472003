import Model from "./Model";
import { win } from "@temsa/helpers/globals";
import { IKeyValueObject } from "@temsa/interfaces/generic";
import { get, lookup, config, location, kebabCase } from "@temsa/helpers/utils";
import { formatMoney, formatNumber, IFormatMoneyOptions } from "accounting-js";
import ArrayCollection from "@temsa/extensions/ArrayCollection";
import envService from "../services/envService";

export default class Advertisement extends Model {
  public collection: string = 'advertisements';
  static readonly DETAIL_TRANSLATION_KEY: string = win('_region')+'-'+win('_language')+'-advertisement-detail';
  static readonly TRANSLATION_KEY: string = win('_region')+'-'+win('_language')+'-advertisements';
  private numberFormatOpts = {
    decimal: '.',
    thousand: '.',
    precision: 0,
  } as IFormatMoneyOptions;

  constructor(attr: IKeyValueObject = {}) {
    super(attr);
  }

  public get year(): number {
    return get('year', this.attributes);
  }

  public get mileage(): string {
    return formatNumber(get('mileage', this.attributes), this.numberFormatOpts) + ' ' + get('mileage_type', this.attributes);
  }

  public get euro_norm() {
    return lookup(get('euro_norm', this.attributes));
  }

  public get number_of_seats() {
    return get('number_of_seats', this.attributes);
  }

  public get price() {
    const currency = this.attributes.currency;
    const symbol: string = config(`moneySymbols.${currency}`);
    const formats: string[] = config('moneyFormats')[currency];
    const moneyFormat = (new ArrayCollection(formats)).toObject(['thousand', 'decimal', 'format']);
    const price = get('price', this.attributes);
    if (typeof price === 'undefined' || price === 0) {
      return win('_contactForSale');
    }

    return formatMoney(
      price,
      {
        symbol,
        precision: 0,
        ...moneyFormat
      } as IFormatMoneyOptions
    );
  }

  public get model(): string {
    return lookup(get('model', this.attributes));
  }

  public get seats(): string {
    return get('seats', this.attributes);
  }

  public get seats_layout(): string {
    return get('seats_layout', this.attributes);
  }

  public get registration_date(): string {
    return get('registration_date', this.attributes);
  }

  public get engine_power(): string {
    return get('engine_power', this.attributes) + ' HP';
  }

  public get fuel_type(): string {
    return lookup(get('fuel_type', this.attributes));
  }

  public get gearing_type(): string {
    return lookup(get('gearing_type', this.attributes));
  }

  public get gvwr(): string {
    return formatNumber(get('gvwr', this.attributes), this.numberFormatOpts) + ' ' + get('gvwr_type', this.attributes);
  }

  public get color(): string {
    return lookup(get('color', this.attributes) || '');
  }

  public get condition(): string {
    return lookup(get('condition', this.attributes) || '');
  }

  public get detailUrl(): string {
    const route = win('_listing');
    const regionCode = location();
    const shortId = this.attributes.short_id.toUpperCase();
    const partials = [this.attributes.year, 'temsa', lookup(this.attributes.model)];
    const slug = kebabCase(partials.join(' '))+'-'+shortId;
    return [envService.get('api.baseUrl'), regionCode, route,slug].join('/');
  }
}